//
// Global init of core components
//

// Init components
const KTComponents = function () {
	// Public methods
	return {
		init: function () {
			window.KTApp.init();
			window.KTDrawer.init();
			window.KTMenu.init();
			window.KTScroll.init();
			window.KTSticky.init();
			window.KTSwapper.init();
			window.KTToggle.init();
			window.KTScrolltop.init();
			window.KTDialer.init();
			window.KTImageInput.init();
			window.KTPasswordMeter.init();
		}
	}
}();

// On document ready
if (document.readyState === "loading") {
	document.addEventListener("DOMContentLoaded", function() {
		KTComponents.init();
	});
 } else {
	KTComponents.init();
 }

 // Init page loader
window.addEventListener("load", function() {
	window.KTApp.hidePageLoading();
});

// Declare KTApp for Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
	window.KTComponents = module.exports = KTComponents;
}